/*! 🧮🧩 2021*/
import Vue from 'vue';
import Vuex from 'vuex';
import { DateTime } from 'luxon';
Vue.use(Vuex);

const debug = false;

const isFutureEvent = value => {
    const currentTime = Date.now();
    const postTime = DateTime.fromFormat(value.show_date_user, 'yyyyMMdd');
    return postTime.toMillis() > currentTime;
};

export default new Vuex.Store({
    //modules: {
    //    spark,
    //    security,
    //},
    state: {
        count: 0,
        artistId: 176,
        showBurger: false,
        nav: {
            Music: {
                path: '/music/',
                external: false,
            },
            News: {
                path: '/news/',
                external: false,
            },
            Live: {
                path: '/live/',
                external: false,
            },
            Photos: {
                path: '/photos/',
                external: false,
            },
            Store: {
                path: 'https://store.palomafaith.com/',
                external: true,
            },
            Videos: {
                path: '/videos/',
                external: false,
            },
            About: {
                path: '/about/',
                external: false,
            },
            Charities: {
                path: '/charities/',
                external: false,
            },
            Book: {
                path: '/book-tour/',
                external: false,
            },
        },
        carousels: [],
        artists_data: [],
        releases: [],
        posts: [],
        tour_dates: [],
        videos: [],
        faq: [],
        gallery_images: [],
        bedtime: [],
        booktour: [],
    },
    mutations: {
        setBurger(state, update) {
            state.showBurger = update;
        },
        update(state, update) {
            const payloadKey = update.payload.replace('-', '_');
            state[payloadKey] = update.value;
            return true;
        },
    },
    actions: {
        async bookTour({ state, commit }, payload) {
            const payloadKey = payload.replace('-', '_');
            if (state[payloadKey].length > 0) {
                return;
            }
            try {
                const response = await fetch('https://cdn-p.smehost.net/sites/5cfaf3980b294dd89a79248f35560b2f/mothership/artist/1292/tour_dates.json',
                    { method: 'GET' });
                let data = await response.json();
                if (Array.isArray(data) === false) {
                    commit('update', {
                        payload,
                        value: [data],
                    });
                } else {
                    switch (payload) {
                    case 'tour_dates':
                        data = data.filter(isFutureEvent);
                        data.sort((a, b) => {
                            return (
                                parseInt(a.show_date_user, 10) -
                  parseInt(b.show_date_user, 10)
                            );
                        });

                        break;
                    }

                    data.sort((a, b) => {
                        return parseInt(a.order, 10) - parseInt(b.order, 10);
                    });
                    commit('update', {
                        payload,
                        value: data,
                    });
                }
            } catch {
                //handle error
            }
        },
        async load({ state, commit }, payload) {
            const payloadKey = payload.replace('-', '_');
            if (state[payloadKey].length > 0) {
                return;
            }
            try {
                const response = await fetch(`https://cdn-p.smehost.net/sites/5cfaf3980b294dd89a79248f35560b2f/mothership/artist/${state.artistId}/${payload}.json`,
                    { method: 'GET' });
                let data = await response.json();
                if (Array.isArray(data) === false) {
                    commit('update', {
                        payload,
                        value: [data],
                    });
                } else {
                    switch (payload) {
                    case 'tour_dates':
                        data = data.filter(isFutureEvent);
                        data.sort((a, b) => {
                            return (
                                parseInt(a.show_date_user, 10) -
                  parseInt(b.show_date_user, 10)
                            );
                        });

                        break;
                    case 'releases':
                        data.sort((a, b) => {
                            return (
                                parseInt(b.releases_date, 10) - parseInt(a.releases_date, 10)
                            );
                        });
                        break;
                    }

                    data.sort((a, b) => {
                        return parseInt(a.order, 10) - parseInt(b.order, 10);
                    });
                    commit('update', {
                        payload,
                        value: data,
                    });
                }
            } catch {
                //handle error
            }
        },
        async loadBedtime({ commit }, payload) {
            try {
                const bedtime = await fetch('../assets/feed/palomas-bedtime.json');
                const bedtimeData = await bedtime.json();
                commit('update', {
                    payload,
                    value: bedtimeData.mainEntity,
                });
            } catch {
                //handle error
            }
        },
    },
    strict: debug,
    //plugins: debug ? [createLogger()] : [],
});
